import http from "../http";

const common = {
    /*科目学习排行*/
    getScores: function (params) {
        return http.get('/user/getscores', params)
    },/*科目学习时间*/
    getlastweekdata: function (params) {
        return http.get('/userduration/getlastweekdata', params)
    },/*科目计时*/
    addduration: function (params) {
        return http.post('/userduration/addduration', params)
    },/*文件上传*/
    uploadFile: function (params) {
        return http.post('/common/upload', params)
    },
    /*添加录音*/
    addRecorde: function (params) {
        return http.post('/userrecording/add_recording', params)
    },

    /*token检测*/
    checkToken: function (params) {
        return http.get('/token/check', params)
    },
    /*token刷新*/
    refreshToken: function (params) {
        return http.get('/token/refresh', params)
    },
    /*会员登录*/
    userLogin: function (params) {
        return http.post('/user/login', params)
    },
    /*会员退出*/
    userLogout: function (params) {
        return http.post('/user/logout', params)
    },
    /*获取科目列表*/
    getClassNav: function (params) {
        return http.get('/index/getnav', params)
    },
    /*获取用户最近学习*/
    getHistory: function (params) {
        return http.post('/userlearning/get_userlearning', params)
    },

    /*获取科目下分类*/
    getClassType: function (params) {
        return http.post('/index/getnav_class', params)
    },
    /*获取版本*/
    getVersion: function (params) {
        return http.get('/bookbanben/index', params)
    },
    /*获取课本*/
    getCourse: function (params) {
        return http.post('/course/getcourse', params)
    },

    /*菜单模块*/
    getCourseMenu: function (params) {
        return http.post('/course/get_course_type_contall', params)
    },

    /*获取章节列表*/
    getChapters: function (parmas) {
        return http.post('/course/get_course_chapters', parmas)
    },

    /*保存所选版本*/
    saveCheckVersion: function (parmas) {
        return http.post('/bookbanben/add_select_bookversion', parmas)
    },
    /*获取用户选择版本*/
    getSelectVersion: function (params) {
        return http.get('/bookbanben/get_select_bookversion', params)
    },

    /*获取图书分类*/
    getLibraryType: function (params) {
        return http.post('/library/get_library_nav', params)
    },

    /*获取图书列表*/
    getLibraryList: function (params) {
        return http.post('/library/get_library_tushu', params)
    },
    /*  获得图书的章节  */
    getBookChapters: function (params) {
        return http.post('/library/get_tushu_chapters', params)
    },

    /*  获得图书的章节内容  */
    getBookChapterContent: function (params) {
        return http.post('/library/get_tushuchapter_content', params)
    },
    /*  添加阅读数量  */
    addViewNum: function (params) {
        return http.post('/library/add_library_num', params)
    },

    /*  添加最近于都章节  */
    addRecentBooks: function (params) {
        return http.post('/library/add_recentbooks', params)
    },

    /*获取错题本列表*/
    getErrorBookNavList: function (params) {
        return http.get('/mytopicbook/get_papeid_info', params)
    },

    /*获取课文*/
    getCourseText: function (params) {
        return http.post('/course/get_mjld', params)
    },

    /*获取课文朗读语音*/
    getCourseAudio: function (params) {
        return http.post('/bdtts/index', params)
    },
    /*  获得作者内容*/
    getAuthInfo: function (params) {
        return http.post('/course/get_auth_info', params)
    },
    /*重置密码*/
    changePassword: function (params) {
        return http.post('/user/resetpwd', params)
    },

    /*查看用户录音*/
    getRecordList: function (params) {
        return http.post('/userrecording/index', params)
    },

    /*查看录音详情*/
    getRecordDetail: function (params) {
        return http.post('/userrecording/get_recording', params)
    },
    /*删除录音*/
    deleteRecord: function (params) {
        return http.post('/userrecording/del', params)
    },

    /*修改个人信息*/
    changeUserInfs: function (params) {
        return http.post('/user/profile', params)
    },

    /*获取个人信息*/
    getUserInfs: function (params) {
        return http.get('/user/userinfo', params)
    },


    /* 查看科目错题*/
    getErrorBookDataList: function (params) {
        return http.get('/mytopicbook/index', params)
    },

    /* 添加错题*/
    addErrorQuestion: function (params) {
        return http.post('/mytopicbook/add_topicbook', params)
    },
    /* 删除错题*/
    deleteErrorQuestion: function (params) {
        return http.post('/mytopicbook/del_mytopicbook', params)
    },

    /* 获取试题版本*/
    getTestPapeInfo: function (params) {
        return http.post('/testpape/get_testpapeinfo', params)
    },
    /* 获取试题分类*/
    getTestPapeType: function (params) {
        return http.post('/testpape/get_testpapeinfo_pape', params)
    },
    /* 获取试题*/
    getTestPapeData: function (params) {
        return http.post('/testpape/get_testpapertype_data', params)
    },
    /* 获取图文内容*/
    getImageTextContent: function (params) {
        return http.post('/course/getchaptercontent', params)
    },

    /* 获取试题年份*/
    getPaperYear: function (params) {
        return http.get('/papeyear/get_papeyear', params)
    },

    /* 获取真题试卷*/
    getPaperContent: function (params) {
        return http.post('/testpape/get_year_pape', params)
    },


    /* 添加用户学习记录*/
    addLearnHistory: function (params) {
        return http.post('/userlearning/add_userlearning', params)
    },

    /*站点信息*/
    webInfs: function (params) {
        return http.get('/common/getsiteinfo', params)
    },

    /*获取用户年级列表*/
    getUserGrade: function (params) {
        return http.get('/gradeselectlist/get_gradelist', params)
    },
    /*添加用户基础信息*/
    addUserBaseInfs: function (params) {
        return http.post('/user/add_userinfo', params)
    },

    /*添加用户做题次数*/
    addTestPapeNum: function (params) {
        return http.post('/testpape/add_testpape_number', params)
    },

    /*发送验证码*/
    sendSms: function (params) {
        return http.post('/sms/send', params)
    },

    /*校验验证码*/
    checkSms: function (params) {
        return http.post('/sms/check', params)
    },
    
    /*注册会员*/
    siginUser: function (params) {
        return http.post('/user/register', params)
    },

    /*验证激活码*/
    checkActiveCode: function (params) {
        return http.post('/activationcode/activation', params)
    },
    /*手机号码登录*/
    loginPhone: function (params) {
        return http.post('/user/authenticationcodelogin', params)
    },

    /*单词集中突破*/
    getWordBreakThrough: function (params) {
        return http.get('/specialenglishtraining/get_wordsetall', params)
    },

    /*单词集中突破 练习*/
    getWordBreakThroughTest: function (params) {
        return http.get('/specialenglishtraining/get_practices', params)
    },
	/*单词集中突破 记录当前索引*/
    setwordcord: function (params) {
        return http.post('/wordrecord/setwordcord', params)
    },
 
    // 获取学习记录数
    getuserwordnum: function(params) {
    	return http.get('/specialenglishtraining/getuserwordnum', params)
    },
    /*获取年份下的城市*/
    getPaperCitys: function (params) {
        return http.get('/papeyear/get_papecitys', params)
    },
    
    
    //科目列表接口
    getcoursetypelist: function (params) {
      return http.get("/wenda/getcoursetypelist", params);
    },
    //科目问答列表接口
    getwendalist: function (params) {
      return http.get("/wenda/getwendalist", params);
    },
    //浏览量接口
    getaddviewnum: function (params) {
      return http.get("/wenda/addviewnum", params);
    },
    //我回复的帖子接口
    getmyhuifulist: function (params) {
      return http.get("/wenda/getmyhuifulist", params);
    },
    //发布问答接口
    getaddwenti: function (params) {
      return http.post("/wenda/addwenti", params);
    },
    //回帖接口
    getaddhuida: function (params) {
      return http.post("/wenda/addhuida", params);
    }, 
	//我回复的帖子删除接口
	getwendadeltie: function (params) {
	  return http.get("/wenda/deltie", params);
	},
};

export default common
